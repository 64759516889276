
















import type { BasicSettings } from "@/api/Settings"
import Settings from "@/api/Settings"
import AdminForm from "@/components/AdminForm.vue"
import Alert from "@/components/Alert.vue"
import { useStatus } from "@/composables/status"
import { useUser } from "@/store/user"

export default defineComponent({
  components: { AdminForm, Alert },
  setup(_, { root }) {
    const user = useUser()
    const { statusAlert, resetStatus, setStatus } = useStatus()

    const loading = ref(true)
    const settings = reactive<BasicSettings>({
      accentColor: "#000000",
      backgroundColor: "#ffffff",
      title: "Thingsboard Pipelines",
    })

    async function fetchSettings() {
      resetStatus()
      const response = await Settings.get()
      if (response.data != null) {
        settings.title = response.data.title
        settings.accentColor = response.data.accentColor
        settings.backgroundColor = response.data.backgroundColor
      } else {
        setStatus("error", response.error)
      }

      loading.value = false
    }

    watchEffect(() => {
      if (!user.isAdmin) root.$router.replace("/login")
    })
    onMounted(fetchSettings)

    return { fetchSettings, loading, settings, statusAlert, user }
  },
})
