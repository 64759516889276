
















































import { mdiEyedropper } from "@mdi/js"

import type { BasicSettings } from "@/api/Settings"
import Settings from "@/api/Settings"
import { useStatus } from "@/composables/status"
import { useSettings } from "@/store/settings"
import { useUser } from "@/store/user"

import Alert from "./Alert.vue"

interface IVForm {
  validate: () => boolean
  reset: () => void
}

export default defineComponent({
  components: { Alert },
  emits: ["update"],
  props: {
    settings: {
      required: true,
      type: Object as () => BasicSettings,
    },
  },
  setup(props) {
    const user = useUser()
    const settings = useSettings()
    const { statusAlert, setStatus, resetStatus } = useStatus()
    const formRef = ref<IVForm | null>(null)

    const valid = ref(false)

    const form = reactive({
      accentColor: props.settings.accentColor,
      backgroundColor: props.settings.backgroundColor,
      title: props.settings.title,
    } as BasicSettings)

    const favicon = ref(null as File | null)
    const logo = ref(null as File | null)

    const pickers: (keyof BasicSettings)[] = ["accentColor", "backgroundColor"]

    const pickerOpen = ref([false, false])

    function updatePicker(val: string, index: number) {
      if (pickerOpen.value[index]) {
        form[pickers[index]] = val
      }
    }

    function onSubmit() {
      resetStatus()
      formRef.value?.validate()
      if (valid.value) updateForm()
    }

    async function updateForm() {
      const response = await Settings.update(form, user.authConfig)

      if (response.data != null) {
        await settings.updateSettings()
        setStatus("success", "Settings updated")
      } else {
        setStatus("error", response.error)
      }

      if (favicon.value) {
        const response = await Settings.putFavicon(favicon.value, user.authConfig)

        if (response.data == null) {
          setStatus("error", response.error)
        }
      }

      if (logo.value) {
        const response = await Settings.putLogo(logo.value, user.authConfig)

        if (response.data == null) {
          setStatus("error", response.error)
        }
      }
    }

    return {
      favicon,
      form,
      formRef,
      logo,
      mdiEyedropper,
      onSubmit,
      pickerOpen,
      resetStatus,
      statusAlert,
      updatePicker,
      valid,
    }
  },
})
